<template>
  <div class="organization pt-3">
    <data-table
      v-if="!reportId || reportId === 'dashboard'"
      ref="table"
      :actions="isUserInternalAdmin ? actions : []"
    />

    <org-profile v-else />

    <org-modal />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import mixin from '../components/mixin'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'Organization',
  components: {
    OrgModal: () => import('../components/OrgModal'),
    OrgProfile: () => import('../components/Org'),
  },
  mixins: [mixin],
  data() {
    return {
      actions: [
        {
          event: 'internal/showFormOrganizationUpdate',
          name: 'Edit',
        },
        {
          event: 'internal/showFormOrganizationDelete',
          name: 'Delete',
        },
        {
          event: 'internal/goToOrganization',
          name: 'View Profile',
        },
      ],
    }
  },
  computed: {
    ...mapAuthGetters(['isUserInternalAdmin']),
    ...mapToolsState({
      reportId: (state) => state.reportId,
    }),
  },
}
</script>
